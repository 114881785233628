import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useUserAuth } from "~/context/userAuthContext";
import classNames from 'classnames/bind';
import styles from './Signup.module.scss';


const cx = classNames.bind(styles);

const Signup = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const { signUp } = useUserAuth();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); 
    try {
      await signUp(email, password);
      navigate("/");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('box')+' p-4'}>
        <h2 className="mb-3">Đăng ký Tài khoản</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Địa chỉ email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Mật khẩu ít nhất 6 ký tự"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <div className="d-grid gap-2">
            <Button variant="primary" type="Submit">
              Đăng ký
            </Button>
          </div>
        </Form>
      </div>
      <div className={cx('box')+" p-4 mt-3 text-center"}>
        Bạn đã có tài khoản? <Link to="/">Đăng nhập</Link>
      </div>
    </div>
  );
};

export default Signup;
